// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

interface DecodedToken {
  exp: number;
  organizationStatus: string;
  userStatus: string;
}

const isTokenExpired = (expTime: number): boolean => {

  try {
    const currentTime = Date.now() / 1000;
    return expTime <= currentTime;
  } catch (error) {
    return false;
  }
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const token = localStorage.getItem('jwtToken');

  if (token) {

    const decodedToken: DecodedToken = jwtDecode(token);
    console.log("-------------")
    console.log(decodedToken);

    if (isTokenExpired(decodedToken.exp)) {   
      console.log("token expired"); 
      return <Navigate to="/sign-in" replace />;
    }

    if (decodedToken.userStatus === 'registered') {
      console.log("missing password");
      //return <Navigate to="/account" replace />;
    }

    if (decodedToken.organizationStatus === 'pending') {
      console.log("missing subscription");
      //return <Navigate to="/sign-up" replace state={{ redirectUrl  }} />;
    }

    return element;
  }

  return <Navigate to="/sign-in" replace />;
};

export default ProtectedRoute;
