// src/contexts/AuthContext.tsx
import axios from 'axios';
import React, { createContext, useState, useContext, ReactNode, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthContextType {
  signIn: (token: string) => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasNavigated, setHasNavigated] = useState(false);

  useLayoutEffect(() => {
    console.log('AuthContext useEffect');

    if (!hasNavigated) {
      const lastVisitedRoute = localStorage.getItem('lastVisitedRoute');
      if (lastVisitedRoute && lastVisitedRoute !== location.pathname) {
        navigate(lastVisitedRoute);
      }
      setHasNavigated(true); // prevent further navigation
    }

  }, [navigate, location.pathname, hasNavigated]);

  // Store the current route as the last visited route
  useLayoutEffect(() => {
    localStorage.setItem('lastVisitedRoute', location.pathname);
  }, [location.pathname]);

  const signIn = (token: string) => {
    localStorage.setItem('jwtToken', token);
  };

  const signOut = async () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      const values = { token };

      try {
        await axios.post(`${process.env.REACT_APP_API_DOMAIN}/user/sign-out`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error('Error during signout:', error);
      } finally {
        localStorage.removeItem('jwtToken');
        navigate('/sign-in');
      }
    }
  };

  return (
    <AuthContext.Provider value={{ signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;