import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, message, Checkbox, Select, Row, Col } from 'antd';
import axios from 'axios';
import '../../assets/styles/modalForm.css';   

interface EditIncidentModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  incident: any;
}

const EditIncidentModal: React.FC<EditIncidentModalProps> = ({ visible, onClose, onSuccess, incident }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (incident) {
      form.setFieldsValue(incident);
    } else {
      form.resetFields();
    }
  }, [incident, form]);
  
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('No JWT found in localStorage');
      }

      // Update incident
      await axios.put(`${process.env.REACT_APP_API_DOMAIN}/incident/${incident.key}`, values, {
        headers: {
           Authorization: `Bearer ${token}`,
        },
      });
      message.success('Incident updated successfully');
      form.resetFields();
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error(error);

      // Access the error message from the API response
      const apiErrorMessage = error.response?.data?.message || 'Failed to update incident';
      message.error(`Failed to update incident - ${apiErrorMessage}`);
    } finally { 
          
    }
  };

  const inputStyle = { height: '50px' };
  return (
    <Modal
      title={'Edit Incident'}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                className="row-col"
                layout="vertical" 
              >
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ }]}
                >
                <Input.TextArea rows={4} readOnly disabled/>
                </Form.Item>
                  <>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          name="firstName"
                          label="First Name"
                          rules={[{ }]}
                        >
                          <Input style={inputStyle} readOnly disabled/>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="lastName"
                          label="Last Name"
                          rules={[{ }]}
                        >
                          <Input style={inputStyle} readOnly disabled/>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="emailAddress"
                          label="Email Address"
                          rules={[{ }]}
                        >
                          <Input style={inputStyle} readOnly disabled/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                            name="phoneNumber"
                            label="Phone Number"
                            rules={[{ }]}
                          >
                            <Input style={inputStyle} readOnly disabled/>
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="reporterType"
                          label="Relation"
                          rules={[{ }]}
                          initialValue=""
                        >
                          <Input style={inputStyle} readOnly disabled/>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="location"
                          label="Location"
                          rules={[{  }]}
                          >
                          <Input style={inputStyle} readOnly disabled/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>

                      <Col span={12}></Col>
                    </Row>
                  </>

                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: 'Please select the type' }]}
                >
                  <Input style={inputStyle} readOnly disabled/>
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="incidentDate"
                      label="Incident Date"
                      rules={[{ required: true, message: 'Please enter the incident date' }]}
                    >
                      <Input type="date" style={inputStyle} readOnly disabled/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="discoveryDate"
                      label="Discovery Date"
                      rules={[{ required: true, message: 'Please enter the discovery date' }]}
                    >
                      <Input type="date" style={inputStyle} readOnly disabled/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="large"
                      valuePropName="checked"
                    >
                      <Checkbox name="large" disabled>
                        Were more than 500 individuals impacted by this incident?
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="thirdParty"
                      valuePropName="checked"
                    >
                      <Checkbox name="thirdParty" disabled>
                        Was a third party involved with this incident?
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="status"
                      label="Incident Status"
                    >
                    <Select placeholder="Incident Status" className="custom-select">
                      <Select.Option value="New">New</Select.Option>
                      <Select.Option value="Reviewed">Reviewed</Select.Option>
                      <Select.Option value="Under Investigation">Under Investigation</Select.Option>
                      <Select.Option value="Verified">Verified</Select.Option>
                      <Select.Option value="Unverified">Unverified</Select.Option>
                      <Select.Option value="Archived">Archived</Select.Option>
                    </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="notes"
                  label="Incident Notes"
                  rules={[{ }]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>

    </Modal>
  );
};

export default EditIncidentModal;