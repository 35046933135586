/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useCallback, useEffect, useState } from "react";

import {
  Card,
  Col,
  Row,
  message,
  Button,
  Dropdown,
  Menu,
  Table,
  Modal,
  Checkbox,
} from "antd";
import {
  CheckOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

import Main from "../components/layout/Main";
import axios from "axios";
import EditIncidentModal from "../components/modal/EditIncident";
import Title from "antd/lib/typography/Title";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const { confirm } = Modal;

const AccessRequests: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const token = localStorage.getItem('jwtToken');
  const [showArchived, setShowArchived] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const filteredData = showArchived ? data : data.filter(incident => incident.status !== 'Archived');

  const handlePublishClick = () => {
    navigate('/incidents/publish'); // Navigate to /incidents/publish
  };

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '400px',
      render: (text: any) => (
        <div style={{
          width: '100%',
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
        }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any) => (
        <div style={{  }}>
          {record.status}
        </div>
      ),
    },
    {
      title: 'Reported',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Occurred',
      dataIndex: 'incidentDate',
      key: 'incidentDate',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => (
        <div style={{ width: '150px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
          {type}
        </div>
      )
    }, 
    {
      title: 'Over 500?',
      dataIndex: 'large',
      key: 'large',
      render: (large: boolean) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {large ? <CheckOutlined style={{ color: 'green' }} /> : <div />}
        </div>
      )
    },
    {
      title: 'Third Party?',
      dataIndex: 'thirdParty',
      key: 'thirdParty',
      render: (thirdParty: boolean) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {thirdParty ? <CheckOutlined style={{ color: 'green' }} /> : <div />}
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" onClick={() => editIncident(record)}>Edit</Menu.Item>
              <Menu.Divider />            
              <Menu.Item key="archive" style={{ color: 'red' }} onClick={() => showArchiveConfirm(record.key)}>Archive</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <EllipsisOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    }
  ];

  const capitalize = (str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const fetchData = useCallback(async () => {
    try {
      if (!token) {
        throw new Error('Token not found');
       }

      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/incident/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });;
      const users = response.data.map((incident: any, index: any) => ({
          key: incident.id,
          description: incident.description,
          firstName: incident.firstName,
          lastName: incident.lastName,
          emailAddress: incident.emailAddress,
          phoneNumber: incident.phoneNumber,
          location: incident.location,
          reporterType: incident.reporterType,
          status: capitalize(incident.status),
          type: incident.type, 
          createdAt: new Date(incident.createdAt).toLocaleDateString(),
          discoveryDate: new Date(incident.discoveryDate).toLocaleDateString(), 
          incidentDate: new Date(incident.incidentDate).toLocaleDateString(),
          large: incident.large, 
          thirdParty: incident.thirdParty, 
          notes: incident.notes,
          }));
        setData(users);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },[token]);



  useEffect(() => { 
    fetchData();
  }, [fetchData]);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleModalSuccess = () => {
    fetchData();
    setIsModalVisible(false);
  };

  const editIncident = (user: any) => {
    setSelectedIncident(user);
    setIsModalVisible(true);
  };

  const showArchiveConfirm = (userId: string) => {
    confirm({
      title: 'Are you sure you to archive this incident?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.put(`${process.env.REACT_APP_API_DOMAIN}/incident/${userId}`, { status: 'Archived' }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          message.success('User deleted successfully');
          fetchData(); // Refresh the employee list
        } catch (error) {
          message.error('Error deleting user:');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  function handleShowArchivedChange(e: CheckboxChangeEvent): void {
    setShowArchived(e.target.checked);
  }

  return (
    <>
      <Main>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Title level={4} style={{ margin: 0 }}>Information Access Requests</Title>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    <Checkbox onChange={handleShowArchivedChange}>Show Archived</Checkbox>
                    <Button type="primary" onClick={handlePublishClick} style={{ marginLeft: '8px' }}>Incident Report Notice</Button>
                  </div>
                </div>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  rowKey="id"
                />
              </div>
            </Card>

              {/*<Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Projects Table"
                extra={
                  <>
                    <Radio.Group onChange={formProps.onChange} defaultValue="all">
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="online">ONLINE</Radio.Button>
                      <Radio.Button value="store">STORES</Radio.Button>
                    </Radio.Group>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={project}
                    dataSource={dataproject}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
                <div className="uploadfile pb-15 shadow-none">
                  <Upload {...formProps}>
                    <Button
                      type="dashed"
                      className="ant-full-box"
                      icon={<ToTopOutlined />}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </Card>*/}
            </Col>
          </Row>
        </div>
      </Main>
      <EditIncidentModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSuccess={handleModalSuccess}
        incident={selectedIncident}
      />
    </>
  );
  
}

export default AccessRequests;
