import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, message, Checkbox, Select, Row, Col } from 'antd';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import '../../assets/styles/modalForm.css';   

interface AddEmployeeModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  user: any;
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({ visible, onClose, onSuccess, user }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
  }, [user, form]);
  
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('No JWT found in localStorage');
      }

      const decodedToken: any = jwtDecode(token);  
      const organization = decodedToken.organization;
      // Include the organization ID in the values
      const payload = { ...values, organization};

      if (user) {
        // Update user
        await axios.put(`${process.env.REACT_APP_API_DOMAIN}/user/${user.key}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success('User updated successfully');
      } else {
        await axios.post(`${process.env.REACT_APP_API_DOMAIN}/user`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success('Employee added successfully');
      }
      form.resetFields();
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error(error);

      // Access the error message from the API response
      const apiErrorMessage = error.response?.data?.message || 'Failed to add employee';
      message.error(`Failed to add employee - ${apiErrorMessage}`);
    } finally {     
      setLoading(false);
    }
  };

  const formItemStyle = { width: '100%' };
  const inputStyle = { height: '50px' };
  return (
    <Modal
      title={user ? 'Edit Employee' : 'Add Employee'}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} layout="vertical">
        <div className="inline-form-items">
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please enter the first name' }]}
            style={formItemStyle}
          >
            <Input style={inputStyle} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please enter the last name' }]}
            style={formItemStyle}
          >
            <Input style={inputStyle} />
          </Form.Item>
        </div>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Please enter the email address' }]}
          style={formItemStyle}
        >
          <Input type="email" style={inputStyle}/>
        </Form.Item>
        <div className="inline-form-items">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ }]}
            style={formItemStyle}
          >
            <Input style={inputStyle}/>
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: 'Please select the type!' }]}
            style={formItemStyle}
          >
            <Select placeholder="Select a role" className="custom-select">
              <Select.Option value="fulltime">Full Time</Select.Option>
              <Select.Option value="parttime">Part Time</Select.Option>
              <Select.Option value="contractor">Contractor</Select.Option>
            </Select>
          </Form.Item>
        </div>
        {!showPasswordFields && (
          <Form.Item>
            <button
              onClick={() => { setShowPasswordFields(true) } } 
              style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}
            >
              Manually set a password?
            </button>
          </Form.Item>
        )}
        {showPasswordFields && (
        <div className="inline-form-items" >
            <Form.Item
                name="password"
                label="Password"
                rules={[{ }]}
                style={formItemStyle}
            >
                <Input.Password className="rounded-input" style={inputStyle}/>
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[{ },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                    },
                }),
                ]}
                style={formItemStyle}
            >
                <Input.Password className="rounded-input" style={inputStyle}/>
            </Form.Item>
        </div>
        )}
        <Row justify="space-between" align="middle">
          <Col>
            <Form.Item
              name="administrator"
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
                <Checkbox>Administrator</Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
            Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEmployeeModal;