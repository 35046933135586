/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Button, message, Input } from "antd";
import {QRCodeSVG} from "qrcode.react";
import Main from "../components/layout/Main";
import Title from "antd/lib/typography/Title";
import { jwtDecode } from "jwt-decode";
import html2canvas from "html2canvas"; 


const IncidentPublish: React.FC = () => {
  const [url, setUrl] = useState("");
  const qrCodeRef = useRef<HTMLDivElement>(null); // Create a ref for the QR code

  useEffect(() => {
    const token = localStorage.getItem("jwtToken"); // Replace with your token retrieval logic
    if (token) {
      const decoded: any = jwtDecode(token);
      const organization = decoded.organization; // Adjust based on your JWT structure
      console.log("organization", organization)
      const hash = btoa(organization);

      // Construct the URL
      const constructedUrl = `${process.env.REACT_APP_DOMAIN}/incident/${hash}`;
      setUrl(constructedUrl);
    }
  }, []);

  const handleQRDownload = () => {
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "qrcode.png";
        link.click();
      }).catch(() => {
        message.error("Failed to download QR code");
      });
    }
  };

  /*
  const handlePDFDownload = async () => {
    try {
      const existingPdfBytes = await fetch('../assets/documents/IncidentReportingNotice.pdf').then(res => res.arrayBuffer());
      console.log(existingPdfBytes)
      console.log('fetched')
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      console.log('loaded')
      const pages = pdfDoc.getPages();
      console.log('got pages')
      const firstPage = pages[0];

      // Example: Add text to the first page
      firstPage.drawText('Incident Report Notice', {
        x: 50,
        y: 700,
        size: 24,
        color: rgb(0, 0, 0),
      });
      console.log('about to save')
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      saveAs(blob, 'incidentNotice.pdf');
    } catch (error) {
      console.error(error);
      message.error("Failed to download PDF");
    }
  };
  */
  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={18} xl={18} className="mb-24">
            <Card bordered={false} className="criclebox card-info-2 h-full">
              <Title level={3}>Publish your Incident Report Notice</Title>
              <br />
              <div style={{ marginLeft: '10px', width: '550px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                Depending on your needs, use one of the following methods to publicly share your Incident Report Notice:
              </div>
            </Card>
        </Col>
      </Row>
      {/*
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={18} xl={18} className="mb-24">
          <Card bordered={false} className="criclebox card-info-2 h-full">
            <Title level={5}>1. Incident Notice</Title>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '200px' }}>
                <img src={incidentReportNotice} alt="Incident Report Notice" style={{ width: '100%', height: 'auto', border: '2px solid' }}/>
              </div>
              <div style={{ marginLeft: '25px', width: '400px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                <div>Download this Incident Report Notice to be posted in your workplace.</div>
                <br />
                <Button type="primary" onClick={handlePDFDownload}>
                  Download Incident Notice
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>*/}
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={18} xl={18} className="mb-24">
        <Card bordered={false} className="criclebox card-info-2 h-full">
            <Title level={5}>1. QR Code</Title>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div ref={qrCodeRef}>
                <QRCodeSVG value={url} size={200} />
              </div>
              <div style={{ marginLeft: '25px', width: '400px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                <div>Download this QR Code image and add it to any physical or digital material to be displayed in your workplace</div>
                <br />
                <Button type="primary" onClick={handleQRDownload}>
                  Download QR Code
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} sm={24} lg={18} xl={18} className="mb-24">
          <Card bordered={false} className="criclebox card-info-2 h-full">
            <Title level={5}>2. Direct link</Title>
            <div style={{ marginTop: '15px', marginLeft: '10px', marginBottom: '25px', width: '600px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
              The below URL can be used in physical or digital material, to be displayed in your workplace.  If the URL is too long for your medium, you can use a URL shortening service like <a href='https://bitly.com' target='_blank' rel='noreferrer'>bitly.com</a>.
            </div>  
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input value={url} readOnly style={{ marginRight: '8px', width: '600px' }} />
              <Button type="primary" onClick={() => navigator.clipboard.writeText(url)}>
                Copy URL
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Main>
  );
  
}

export default IncidentPublish;
