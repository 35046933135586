/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Typography,
  Card,
  Form,
  FormInstance,
  Input,
  Checkbox,
  message,
  Select
} from "antd";

import { Link, useLocation } from 'react-router-dom';

import axios from "axios";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;
const { Option } = Select;

interface SignUpProps {

}

const SignUp: React.FC<SignUpProps> = () => { 
  
  const [formHidden, setFormHidden] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const formRef = React.createRef<FormInstance>();
  const inputStyle = { height: '50px' };
  const [initialPlan, setInitialPlan] = useState<string | null>(null);
  const location = useLocation();
  const [form] = Form.useForm();


  /*
  const constructor: any(props: SignUpProps) {
    super(props);
    this.state = {
      confirmDirty: false,
      password: "", 
      formHidden: false,
      redirectUrl: "" 
    };
  }*/

  const onFinish = async(values: any) => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/auth/sign-up`, {values});
      const token = response.data.token;
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('redirect', JSON.stringify(response.data.url));

      setFormHidden(true); // Hide the form
      setRedirectUrl(response.data.url); // Set the redirect URL
      formRef.current?.resetFields(); // Clear the form fields
      //useState({ formHidden: true, redirectUrl: response.data.url }); // Hide the form
      
      // Handle successful response
    } catch (error) {
      const errorMessage = (error as any).response?.data?.message || "Sign up failed. Please try again.";
      message.error(errorMessage);
      console.log(error);
      // Handle error response
    }  
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Account was not created, please see error message(s)");
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const plan = queryParams.get('plan') as string;
    if (plan === 'Essential' || plan === 'Professional') {
      setInitialPlan(plan);
      console.log("Setting initial plan: ", plan);
    }
  }, [location]);

  useEffect(() => {
    if (initialPlan) {
      form.setFieldsValue({ plan: initialPlan });
    }
  }, [initialPlan, form]);

  return (
      <>
        <script src="https://js.chargebee.com/v2/chargebee.js"></script> 
        <div className="layout-default ant-layout layout-sign-up">
          <Header>
            <div className="header-col header-brand">
              <h5>MedComply.ai</h5>
            </div>
            {/*<div className="header-col header-nav">
              <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                <Menu.Item key="1">
                  <Link to="/dashboard">
                    {template}
                    <span> Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/profile">
                    {profile}
                    <span>Profile</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/sign-up">
                    {signup}
                    <span> Sign Up</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/sign-in">
                    {signin}
                    <span> Sign In</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </div>*/}
          </Header>

          <Content className="p-0">
            <div className="sign-up-header">
              <div className="content">
                <Title>Get Started</Title>
                <p className="text-lg">
                  Sign up for a free trial today and let us show you how easy compliance can be.
                </p>
              </div>
            </div>

            <Card
              className="card-signup header-solid h-full ant-card pt-0"
              bordered={false}
            >
              
              {formHidden ? (
                <div className="success-message">
                  Welcome! Please check your inbox for next steps.
                </div>
              ) : (
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                className="row-col"
              >
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "Please input your first name" },
                  ]}
                >
                  <Input placeholder="First Name" style={inputStyle} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "Please input your last name" },
                  ]}
                >
                  <Input placeholder="Last Name"  style={inputStyle}/>
                </Form.Item>
                <Form.Item
                  name="organizationName"
                  rules={[
                    { required: true, message: "Please input your company name" },
                  ]}
                >
                  <Input placeholder="Company name" style={inputStyle}/>
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email" },
                  ]}
                >
                  <Input placeholder="Email" style={inputStyle}/>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                    {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                      message: "Password must be at least 8 characters long with at least one uppercase letter, one lowercase letter, and one number.",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" style={inputStyle}/ >
                </Form.Item>
                <Form.Item
                  name="plan"
                  rules={[{ required: true, message: "Please select your plan!" }]}
                  initialValue={initialPlan}
                >
                  <Select placeholder="Select a plan" className="custom-select">
                    <Option value="essential-USD-Monthly">Essential</Option>
                    <Option value="professional-USD-Monthly">Professional</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>
                    I agree the{" "}
                    <a href="https://www.medcomply.ai/tos.html" className="font-bold text-dark" target="_blank" rel="noreferrer">
                      Terms and Conditions
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    SIGN UP
                  </Button>
                </Form.Item>
                <p className="font-semibold text-muted">
                    Already have an account?{" "}
                    <Link to="/sign-in" className="text-dark font-bold">
                      Sign In
                    </Link>
                  </p>
              </Form>
              )}

            </Card>
          </Content>
            {redirectUrl && (
            <iframe
              title="hidden_iframe"
              src={redirectUrl}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
                zIndex: 9999,
              }}
            />
          )}

          <Footer>
            <Menu mode="horizontal">
              <Menu.Item>
              <a href="https://www.medcomply.ai" target="_blank" rel="noopener noreferrer">
                About Us
              </a>
              </Menu.Item>
              <Menu.Item>
              <a href="https://blog.medcomply.ai" target="_blank" rel="noopener noreferrer">
                Blog
              </a>
              </Menu.Item>
              <a href="https://medcomply.ai/#price" target="_blank" rel="noopener noreferrer">
                Pricing
              </a>
            </Menu>
            <p className="copyright">
              {" "}
              Copyright © 2024 MedComply.ai{" "}
            </p>
          </Footer>
        </div>
      </>
  )
}

export default SignUp;
