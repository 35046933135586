/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Dropdown,
  Menu,
  Modal,
  message,
  Tooltip,
} from "antd";

import Main from "../components/layout/Main";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import AddEmployeeModal from '../components/modal/AddEmployee';
import { CheckOutlined, EllipsisOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { confirm } = Modal;

export default function Employees() {
  const [data, setData] = useState<any[]>([]);
  const token = localStorage.getItem('jwtToken');
  const [selectedUser, setSelectedUser] = useState(null);
  const [organizationUserLimit, setOrganizationUserLimit] = useState<number>(10);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: 'Employee',
      dataIndex: 'employee',
      key: 'employee',
      width: '30%',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        switch (type.toLowerCase()) {
          case 'fulltime':
            return 'Full Time';
          case 'parttime':
            return 'Part Time';
          case 'contractor':
            return 'Contractor';
          default:
            return type;
        }
      },
    },
    {
      title: 'Administrator',
      dataIndex: 'administrator',
      key: 'administrator',
      render: (isAdmin: boolean) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {isAdmin ? <CheckOutlined style={{ color: 'green' }} /> : <div />}
        </div>
      )
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" onClick={() => editUser(record)}>Edit</Menu.Item>
              {record.status === 'Inactive' ? (
              <Menu.Item key="active" onClick={() => activateUser(record.key)}>Activate</Menu.Item>
                ) : (
                  <Menu.Item key="inactive" onClick={() => showInactiveConfirm(record.key)}>Inactivate</Menu.Item>
                )}  
              <Menu.Divider />            
              <Menu.Item key="delete" style={{ color: 'red' }} onClick={() => showDeleteConfirm(record.key)}>Delete</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <EllipsisOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    }
  ];

  const capitalize = (str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const fetchData = useCallback(async () => {
    try {
      if (!token) {
        throw new Error('Token not found');
       }
      const decodedToken: { email: string, organization: string, organizationUserLimit: number } = jwtDecode(token);
      const organization = decodedToken.organization;
      setOrganizationUserLimit(decodedToken.organizationUserLimit);

      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/user/organization/${ organization }`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });;
      const users = response.data.map((user: any, index: any) => ({
          key: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          title: user.title,
          employee: (
            <>
              <Avatar.Group>
                <Avatar
                  className="shape-avatar"
                  shape="square"
                  size={40}
                  style={{ backgroundColor: user.avatarColor || '#303030' }}
                  >
                  {user.firstName.charAt(0).toUpperCase()}{user.lastName.charAt(0).toUpperCase()}
                  </Avatar>
                <div className="avatar-info">
                  <Title level={5}>{user.firstName} {user.lastName}</Title>
                  <p>{user.email}</p>
                </div>
              </Avatar.Group>
            </>
          ),
          function: (
            <>
              <div className="author-info">
                <Title level={5}>{user.role}</Title>
                <p>{user.organization}</p>
              </div>
            </>
          ),
          status: capitalize(user.status),
          administrator: user.administrator,
          type: user.type, 
          created: new Date(user.createdAt).toLocaleDateString(),
      }));
        setData(users);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },[token]);

  useEffect(() => { 
    fetchData();
  }, [fetchData]);

  const handleAddEmployee = () => {
    setSelectedUser(null);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleModalSuccess = () => {
    fetchData();
    setIsModalVisible(false);
  };

  const editUser = (user: any) => {
    setSelectedUser(user);
    setIsModalVisible(true);
  };

  const showDeleteConfirm = (userId: string) => {
    confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.delete(`${process.env.REACT_APP_API_DOMAIN}/user/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          message.success('User deleted successfully');
          fetchData(); // Refresh the employee list
        } catch (error) {
          message.error('Error deleting user:');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const activateUser = async (userId: string) => {
    try {
        await axios.put(`${process.env.REACT_APP_API_DOMAIN}/user/${userId}`, { status: 'active' }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('User status updated to inactive');
      fetchData(); // Refresh the employee list
    } catch (error) {
      // Access the error message from the API response
      const apiErrorMessage = (error as any).response?.data?.error || 'Failed to add employee';
      message.error(`Failed to activate employee - ${apiErrorMessage}`);
    }
  };

  const showInactiveConfirm = (userId: string) => {
    confirm({
      title: 'Are you sure you want to set this user as inactive?',
      content: 'This action will change the user status to inactive.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.put(`${process.env.REACT_APP_API_DOMAIN}/user/${userId}`, { status: 'inactive' }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          message.success('User status updated to inactive');
          fetchData(); // Refresh the employee list
        } catch (error) {
          message.error('Error updating user status');
          console.error('Error updating user status:', error);
        }
      }
    });
  };

  const getActiveOrRegisteredUserCount = () => {
    return data.filter(user => user.status === 'Active' || user.status === 'Registered').length;
  };

  const activeOrRegisteredUsers = getActiveOrRegisteredUserCount();

  return (
    <>
      <Main>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Title level={4} style={{ margin: 0 }}>Employees ({activeOrRegisteredUsers}/{organizationUserLimit})</Title>
                    <Tooltip title={activeOrRegisteredUsers >= organizationUserLimit ? "User limit reached" : ""}>
                      <Button type="primary" 
                              onClick={handleAddEmployee}
                              disabled={activeOrRegisteredUsers >= organizationUserLimit ? true : false}>
                        + Add Employee
                      </Button>
                    </Tooltip>
                  </div>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>

              {/*<Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Projects Table"
                extra={
                  <>
                    <Radio.Group onChange={formProps.onChange} defaultValue="all">
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="online">ONLINE</Radio.Button>
                      <Radio.Button value="store">STORES</Radio.Button>
                    </Radio.Group>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={project}
                    dataSource={dataproject}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
                <div className="uploadfile pb-15 shadow-none">
                  <Upload {...formProps}>
                    <Button
                      type="dashed"
                      className="ant-full-box"
                      icon={<ToTopOutlined />}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </Card>*/}
            </Col>
          </Row>
        </div>
      </Main>
      <AddEmployeeModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSuccess={handleModalSuccess}
        user={selectedUser}
      />
    </>
  );
}
