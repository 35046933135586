/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";

import Main from "../components/layout/Main";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";


const Profile: React.FC = () => {
  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  const getBase64 = (img: Blob, callback: { (imageUrl: any): void; (arg0: string | ArrayBuffer | null): any; }) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: { type: string; size: number; }) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  
  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.originFileObj) {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setLoading(false);
          setImageURL(false);
        });
      }
    }
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const data = [
    {
      title: "Sophie B.",
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      description: "About files I can…",
    },
    {
      title: "Peterson",
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];

  return (
    <>
      <Main>
        <div
          className="profile-nav-bg"
          style={{ backgroundImage: "url(" + BgProfile + ")" }}
        ></div>

        <Card
          className="card-profile-head"
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={12} className="col-info">
                <Avatar.Group>

                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">Sarah Jacob</h4>
                    <p>CEO / Co-Founder</p>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">OVERVIEW</Radio.Button>
                  <Radio.Button value="b">TEAMS</Radio.Button>
                  <Radio.Button value="c">PROJECTS</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          }
        ></Card>

        <Row gutter={[24, 0]}>
          <Col span={24} md={8} className="mb-24 ">
            <Card
              bordered={false}
              className="header-solid h-full"
              title={<h6 className="font-semibold m-0">Platform Settings</h6>}
            >
              <ul className="list settings-list">
                <li>
                  <h6 className="list-header text-sm text-muted">ACCOUNT</h6>
                </li>
                <li>
                  <Switch defaultChecked />

                  <span>Email me when someone follows me</span>
                </li>
                <li>
                  <Switch />
                  <span>Email me when someone answers me</span>
                </li>
                <li>
                  <Switch defaultChecked />
                  <span>Email me when someone mentions me</span>
                </li>
                <li>
                  <h6 className="list-header text-sm text-muted m-0">
                    APPLICATION
                  </h6>
                </li>
                <li>
                  <Switch defaultChecked />
                  <span>New launches and projects</span>
                </li>
                <li>
                  <Switch defaultChecked />
                  <span>Monthly product updates</span>
                </li>
                <li>
                  <Switch defaultChecked />
                  <span>Subscribe to newsletter</span>
                </li>
              </ul>
            </Card>
          </Col>
          <Col span={24} md={8} className="mb-24">
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Profile Information</h6>}
              className="header-solid h-full card-profile-information"
              extra={<Button type="link">{pencil}</Button>}
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <p className="text-dark">
                {" "}
                Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer
                is no. If two equally difficult paths, choose the one more painful
                in the short term (pain avoidance is creating an illusion of
                equality).{" "}
              </p>
              <hr className="my-25" />
              <Descriptions title="Oliver Liam">
                <Descriptions.Item label="Full Name" span={3}>
                  Sarah Emily Jacob
                </Descriptions.Item>
                <Descriptions.Item label="Mobile" span={3}>
                  (44) 123 1234 123
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>
                  sarahjacob@mail.com
                </Descriptions.Item>
                <Descriptions.Item label="Location" span={3}>
                  USA
                </Descriptions.Item>
                <Descriptions.Item label="Social" span={3}>
                  <a href="#pablo" className="mx-5 px-5">
                    {<TwitterOutlined />}
                  </a>
                  <a href="#pablo" className="mx-5 px-5">
                    {<FacebookOutlined style={{ color: "#344e86" }} />}
                  </a>
                  <a href="#pablo" className="mx-5 px-5">
                    {<InstagramOutlined style={{ color: "#e1306c" }} />}
                  </a>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={24} md={8} className="mb-24">
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Conversations</h6>}
              className="header-solid h-full"
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <List
                itemLayout="horizontal"
                dataSource={data}
                split={false}
                className="conversations-list"
                renderItem={(item) => (
                  <List.Item actions={[<Button type="link">REPLY</Button>]}>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
        <Card
          bordered={false}
          className="header-solid mb-24"
          title={
            <>
              <h6 className="font-semibold">Projects</h6>
              <p>Architects design houses</p>
            </>
          }
        >
          <Row gutter={[24, 24]}>
            {project.map((p, index) => (
              <Col span={24} md={12} xl={6} key={index}>
                <Card
                  bordered={false}
                  className="card-project"
                  cover={<img alt="example" src={p.img} />}
                >
                  <div className="card-tag">{p.titlesub}</div>
                  <h5>{p.title}</h5>
                  <p>{p.disciption}</p>
                  <Row gutter={[6, 0]} className="card-footer">
                    <Col span={12}>
                      <Button type="default">VIEW PROJECT</Button>
                    </Col>
                    <Col span={12} className="text-right">
                      <Avatar.Group className="avatar-chips">
                      </Avatar.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
            <Col span={24} md={12} xl={6}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader projects-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageURL ? (
                  <img src={imageURL ? imageURL.toString() : ""} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Col>
          </Row>
        </Card>
      </Main>
    </>
  );
}

export default Profile;
