/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
import {
  Layout,
  Button,
  Card,
  Form,
  Input,
  Checkbox,
  message,
  Select,
  Row,
  Col,
  Switch
} from "antd";

import axios from "axios";
import { useParams } from "react-router-dom";

const { Header, Footer, Content } = Layout;
const { Option } = Select;

const inputStyle = { height: '40px' };
const IncidentReport: React.FC = () => {
  const [isAnonymous, setIsAnonymous] = useState(true);
  const { id } = useParams<{ id: string | undefined }>(); 
  const decodeOrganizationId = (encodedId: string | null): string => {
    try {
      if (!encodedId) throw new Error("No encoded ID")
      console.log(atob(encodedId))
      return atob(encodedId);
    } catch (error) { 
      console.error("Failed to decode organization ID:", error);
      return "";
    }
  };
  
  const organizationId = decodeOrganizationId(id ?? null);
  const [form] = Form.useForm(); 

  const onFinish = async(values: any) => {

    try {
      await axios.post(`${process.env.REACT_APP_API_DOMAIN}/auth/incident/report/`, {values});
      message.success("The incident has been reported");
      form.resetFields(); 
      
      // Handle successful response
    } catch (error) {
      const errorMessage = (error as any).response?.data?.message || "Incident report failed, please try again.";
      message.error(errorMessage);
      console.log(error);
      // Handle error response
    }  
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error("Please complete the missing fields.");
    console.log("Failed:", errorInfo);
  };

  const handleAnonymousChange = (checked: boolean) => {
    setIsAnonymous(checked);
  };

    return (
      <>
        <div className="layout-default ant-layout layout-sign-up">
          <Header>
            <div className="header-col header-brand">
              <h5>MedComply.ai </h5>
            </div>
          </Header>

          <Content className="p-0">
            <div className="incident-header">
              <div className="content">
                {/*<p className="text-lg">
                  Sign up for a free trial today and let us show you how easy compliance can be.
                </p>*/}
              </div>
            </div>

            <Card
              className="card-incident header-solid h-full ant-card pt-0"
              bordered={false}
            >
              <h3>Report an Incident</h3>
              <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="row-col"
                layout="vertical" 
              >
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: 'Description is required' }]}
                >
                  <Input.TextArea rows={4}/>
                </Form.Item>
                <Form.Item
                  name="anonymous"
                  label="Report Anonymously"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Switch defaultChecked onChange={handleAnonymousChange} />
                </Form.Item>

                  {!isAnonymous && (
                  <>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="firstName"
                          label="First Name"
                          rules={[{ }]}
                        >
                          <Input style={inputStyle} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="lastName"
                          label="Last Name"
                          rules={[{ }]}
                        >
                          <Input style={inputStyle} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="emailAddress"
                          label="Email Address"
                          rules={[{ }]}
                        >
                          <Input style={inputStyle} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                            name="phoneNumber"
                            label="Phone Number"
                            rules={[{ }]}
                          >
                            <Input style={inputStyle} />
                          </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="reporterType"
                          label="Relation"
                          rules={[{ }]}
                          initialValue=""
                        >
                          <Select placeholder="Select type" className="custom-incident-select">
                            <Option value="Patient">Patient</Option>
                            <Option value="Employee">Employee</Option>
                            <Option value="Contractor">Contractor</Option>
                            <Option value="Unknown">Unknown</Option>
                            <Option value="Other">Other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}></Col>
                    </Row>
                  </>
                )}
                <Form.Item
                  name="location"
                  label="Location"
                  rules={[{ required: true, message: 'Location is required' }]}
                  >
                  <Input style={inputStyle}/>
                </Form.Item>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: 'Please select the type' }]}
                >
                  <Select placeholder="Select incident type" className="custom-incident-select">
                    <Option value="Unauthorized Access">Unauthorized Access</Option>
                    <Option value="Unauthorized Disclosure">Unauthorized Disclosure</Option>
                    <Option value="Lost or Stolen Device">Lost or Stolen Device</Option>
                    <Option value="Improper Disposal">Improper Disposal</Option>
                    <Option value="Data Breach">Data Breach</Option>
                    <Option value="Unencrypted Data Transmission">Unencrypted Data Transmission</Option>
                    <Option value="HIPAA Training or Policy Violations">HIPAA Training or Policy Violations</Option>
                    <Option value="Improper Use of PHI">Improper Use of PHI</Option>
                    <Option value="Third-Party or Vendor Breach">Third-Party or Vendor Breach</Option>
                    <Option value="Inappropriate Access to Electronic Health Records (EHR)">Inappropriate Access to Electronic Health Records (EHR)</Option>
                    <Option value="Physical Security Breach">Physical Security Breach</Option>
                    <Option value="Failure to Provide Access to PHI">Failure to Provide Access to PHI</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="incidentDate"
                      label="Incident Date"
                      rules={[{ required: true, message: 'Please enter the incident date' }]}
                    >
                      <Input type="date" style={inputStyle} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="discoveryDate"
                      label="Discovery Date"
                      rules={[{ required: true, message: 'Please enter the discovery date' }]}
                    >
                      <Input type="date" style={inputStyle} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                    name="large"
                    valuePropName="checked" >
                  <Checkbox name="large" >
                    Were more than 500 individuals impacted by this incident?
                  </Checkbox>
                </Form.Item>
                <Form.Item
                    name="thirdParty"
                    valuePropName="checked" >
                  <Checkbox name="thirdParty" >
                    Was a third party involved with this incident?
                  </Checkbox>
                </Form.Item>
                <Form.Item name="organizationId" initialValue={organizationId} style={{ display: 'none' }}>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>

            </Card>
          </Content>

          <Footer>
            <p className="copyright">
              {" "}
              Copyright © 2024 MedComply.ai{" "}
            </p>
          </Footer>
        </div>
      </>
    );
}

export default IncidentReport;
