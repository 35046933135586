/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Layout,
  Menu,
  Button,
  Typography,
  Form,
  message, 
  Input,
  Switch,
  Card,
} from "antd";
import { useNavigate } from 'react-router';
import { useAuth } from '../contexts/AuthContext';

const { Title } = Typography;

interface SignInProps {
}

const SignIn: React.FC<SignInProps> = () => {

  //const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const { Header, Footer, Content } = Layout;

  useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      console.log(queryParams)
      const jwtToken = queryParams.get('jwtToken') as string;
      // const chargeBeeCustomerId = queryParams.get('customer_id') as string;
      // const token = localStorage.getItem('jwtToken');
      if (jwtToken) {

        localStorage.setItem('jwtToken', jwtToken);
        setTimeout(() => {
          navigate('/dashboard');
        }, 0);
      }

  }, [navigate]);

  function onChange(checked: boolean) {
    console.log(`switch to ${checked}`);
  }

  const onFinish = async (values: any) => {
    //setLoading(true);
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/auth/sign-in`, values);
        const token = response.data.token;
        signIn(token);
        console.log('Sign-in successful:', response.data);
        message.success('Sign-in successful!');
        navigate('/dashboard');
      } catch (error) {
        console.error('Sign-in error:', error);
        message.error('Sign-in failed. Please try again.');
      } finally {
        //setLoading(false);
      }
  };
    
  const onFinishFailed = (errorInfo: any) => {
      console.error('Failed:', errorInfo);
      //message.error('Sign-in failed. Please check your input and try again.');
  };

  const inputStyle = { height: '50px' };
    
  return (
      <>
        <script src="https://js.chargebee.com/v2/chargebee.js"></script> 
        <div className="layout-default ant-layout layout-sign-up">
          <Header>
            <div className="header-col header-brand">
              <h5>MedComply.ai</h5>
            </div>
            {/*<div className="header-col header-nav">
              <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                <Menu.Item key="1">
                  <Link to="/dashboard">
                    {template}
                    <span> Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/profile">
                    {profile}
                    <span>Profile</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/sign-up">
                    {signup}
                    <span> Sign Up</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/sign-in">
                    {signin}
                    <span> Sign In</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </div>*/}
          </Header>

          <Content className="p-0">
            <div className="sign-up-header">
              <div className="content">
                <Title>Sign In</Title>
                <p className="text-lg">
                  Conitune your compliance journey with MedComply.ai
                </p>
              </div>
            </div>

            <Card
              className="card-signup header-solid h-full ant-card pt-0"
              bordered={false}
            >
              
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" style={inputStyle}/>
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                <Input.Password placeholder="Password" style={inputStyle} />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="aligin-center"
                  valuePropName="checked"
                >
                  <Switch defaultChecked onChange={onChange} />
                  <span style={{ marginLeft: '10px' }}>Remember me</span>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
                <p className="font-semibold text-muted">
                  Don't have an account?{" "}
                  <Link to="/sign-up" className="text-dark font-bold">
                    Sign Up
                  </Link>
                </p>
              </Form>  

            </Card>
          </Content>
          )

          <Footer>
            <Menu mode="horizontal">
              <Menu.Item>Company</Menu.Item>
              <Menu.Item>About Us</Menu.Item>
              <Menu.Item>Teams</Menu.Item>
              <Menu.Item>Products</Menu.Item>
              <Menu.Item>Blogs</Menu.Item>
              <Menu.Item>Pricing</Menu.Item>
            </Menu>
            <p className="copyright">
              {" "}
              Copyright © 2024 MedComply.ai{" "}
            </p>
          </Footer>
        </div>
      </>
  );

}

export default SignIn;

