/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import Main from "../components/layout/Main";

const MyTrainings: React.FC = () => {

  const angle = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      key={0}
    >
      <g id="bank" transform="translate(0.75 0.75)">
        <path
          id="Shape"
          transform="translate(0.707 9.543)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path"
          d="M10.25,0,20.5,9.19H0Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path-2"
          data-name="Path"
          d="M0,.707H20.5"
          transform="translate(0 19.793)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>,
  ];

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const information = [
    {
      title: "HIPAA Annual Training Course 2025",
      description: "HIPAA Annual Training covers the basics of HIPAA compliance and the importance of protecting PHI.",
      time: "30 minutes",
      image: null
    },
  ];

  const newest = [
    {
      avatar: <PlusOutlined style={{ fontSize: 10 }} onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />,
      title: "Annual HIPAA Training",
      description: "Completed on 27 March 2024",
      textclass: "text-fill",
      amountcolor: "text-success",
    },
  ];

  const currentYear = new Date().getFullYear();
  const placeholderImage = 'https://via.placeholder.com/150';

  return (
    <>
        <Main>
          <Row gutter={[24, 0]}>
            <Col span={24} md={16} className="mb-24">
              <Card
                className="header-solid h-full"
                bordered={false}
                title={[<h6 className="font-semibold m-0">Upcoming Trainings</h6>]}
                bodyStyle={{ paddingTop: "0" }}
              >
                <Row gutter={[24, 24]}>
                  {information.map((i, index) => (
                    <Col span={24} key={index}>
                      <Card className="card-billing-info" bordered={true}>
                      <Col span={4} style={{ paddingLeft: 0, paddingRight: '12px' }}>
                        <img
                          src={i.image ? i.image : placeholderImage}
                          alt={i.title}
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <div className="col-info">
                        <Descriptions title={i.title}>
                        <Descriptions.Item label="Run Time" span={3} labelStyle={{ color: '#8c8c8c' }} contentStyle={{ color: '#8c8c8c' }}>
                            {i.time}
                          </Descriptions.Item>
                          <Descriptions.Item label="Description" span={3} labelStyle={{ color: '#8c8c8c' }} contentStyle={{ color: '#8c8c8c' }}>
                            {i.description}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className="col-action">
                          <Button type="link" className="darkbtn">
                            {pencil} Restart 
                          </Button>
                          <Button type="link" className="darkbtn">
                            {angle} Start 
                          </Button>
                      </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
            <Col span={24} md={8} className="mb-24">
              <Card
                bordered={false}
                bodyStyle={{ paddingTop: 0 }}
                className="header-solid h-full  ant-list-yes"
                title={<h6 className="font-semibold m-0">Your Certificates</h6>}
              >
                <List
                  header={<h6>{currentYear}</h6>}
                  className="transactions-list ant-newest"
                  itemLayout="horizontal"
                  dataSource={newest}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar size="large" className={item.textclass}>
                            {item.avatar}
                          </Avatar>
                        }
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Main>
    </>
  );
}

export default MyTrainings;
